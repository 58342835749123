import { tpElement } from '../lib/component-decorator'

@tpElement('copy-item')
export class CopyItem extends HTMLElement {
  private tooltip: HTMLDivElement

  constructor() {
    super()

    this.style.cursor = 'pointer'
    this.style.position = 'relative'

    this.addEventListener('click', () => this.copy())

    this.tooltip = document.createElement('div')
    this.tooltip.role = 'tooltip'
    this.tooltip.classList.add('tooltip')
    this.tooltip.classList.add('bs-tooltip-top')
    this.tooltip.classList.add('bottom')
    this.tooltip.innerHTML = `
      <div class="tooltip-arrow" style="
        left: 50%;
        margin-left: -5px;
      "></div>
      <div class="tooltip-inner" style="border: 1px solid white">
        Kopiert!
      </div>
    `

    document.body.appendChild(this.tooltip)
  }

  private showTooltip() {
    let box = this.getBoundingClientRect()
    let boxt = this.tooltip.getBoundingClientRect()
    console.log(box)

    this.tooltip.style.opacity = '1'

    let left = box.left + box.width / 2 - boxt.width / 2
    let top = box.top

    this.tooltip.style.top = `${top}px`
    this.tooltip.style.left = `${left}px`

    window.setTimeout(() => this.hideTooltip(), 5000)
  }

  private hideTooltip() {
    this.tooltip.style.opacity = '0'
  }

  private copy() {
    navigator.clipboard.writeText(this.dataset.copy!).then(
      () => this.showTooltip(),
      () => console.log('failed')
    )
  }
}
