export function pluralize(
  count: number,
  singular: string,
  plural: string,
  zero: string | undefined = undefined
) {
  if (count > 1) {
    return plural.replace('{}', count.toString())
  } else if (count <= 0 && undefined !== zero) {
    return zero
  }

  return singular.replace('{}', count.toString())
}
