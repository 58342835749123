import { pluralize } from './pluralize'

export function remaining(total_duration: number, progress: number) {
  let remainingTime = Math.floor(total_duration - total_duration * progress)

  if (0 == remainingTime) {
    return ''
  }

  let minutes = Math.floor(remainingTime / 60)
  let displayNumber = minutes ? minutes : remainingTime
  let displayUnit = minutes
    ? pluralize(minutes, 'Minute', 'Minuten')
    : pluralize(remainingTime, 'Sekunde', 'Sekunden')

  return `Noch ${displayNumber} ${displayUnit} übrig`
}
