import { tpElement } from '../lib/component-decorator'
import { CourseProgressInterface } from '../lib/course-progress-interface'
import { remaining } from '../lib/remaining'

@tpElement('course-player-progress')
export class CoursePlayerProgress extends HTMLElement implements CourseProgressInterface {
  private percentage: HTMLSpanElement
  private progressBar: HTMLDivElement
  private durationLeft: HTMLDivElement

  private duration: number
  private progress: number

  constructor() {
    super()

    this.percentage = this.querySelector('.percentage-right > span')!
    this.progressBar = this.querySelector('.progress-bar')!
    this.durationLeft = this.querySelector('.percentage-right .percentage-text')!

    this.duration = parseInt(this.progressBar.getAttribute('aria-valuemax')!)
    this.progress = Math.min(
      this.duration,
      parseInt(this.progressBar.getAttribute('aria-valuenow')!)
    )
  }

  setProgress(progress: number) {
    this.progress = Math.max(this.progress, Math.min(progress, this.duration))

    let playedPercentage = Math.floor((this.progress / this.duration) * 100)
    let seconds = Math.round(this.duration - this.progress)

    this.percentage.innerHTML = `${playedPercentage}%`
    this.progressBar.style.width = `${playedPercentage}%`
    this.durationLeft.innerHTML = remaining(this.duration, playedPercentage / 100)

    this.durationLeft.style.display = 1 > seconds ? 'none' : 'block'
  }
}
