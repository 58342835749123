import { CoursePlayerProgress } from './course-player-progress'
import { CourseProgressInterface } from '../lib/course-progress-interface'
import { tpElement } from '../lib/component-decorator'

@tpElement('course-player')
export class CoursePlayer extends HTMLElement {
  private videoPlayer: HTMLVideoElement
  private playButton: HTMLAnchorElement
  private progressListeners: CourseProgressInterface[] = []
  private reportListeners: CallableFunction[] = []
  private lastTime: number = 0
  private elapsed: number = 0
  public videoId: number = 0

  constructor() {
    super()

    this.videoPlayer = document.querySelector<HTMLVideoElement>('.first-section video')!
    this.playButton = this.videoPlayer.nextElementSibling as HTMLAnchorElement
    this.videoId = parseInt(this.videoPlayer.dataset.video!)
    this.lastTime = parseInt(this.videoPlayer.dataset.progress!)

    this.progressListeners.push(
      this.querySelector<CoursePlayerProgress>('.first-section__progress-box')!
    )

    this.videoPlayer.addEventListener('timeupdate', () => this.timedUpdated())
    this.videoPlayer.addEventListener('ended', () => this.paused())
    this.videoPlayer.addEventListener('seeked', () => this.reportPosition())
    this.videoPlayer.addEventListener('playing', () => this.playing())
    this.videoPlayer.addEventListener('pause', () => this.paused())
  }

  private paused(): void {
    this.reportPosition()
    this.playButton.style.display = 'initial'
  }

  private playing(): void {
    this.playButton.style.display = 'none'
  }

  private timedUpdated(): void {
    if (this.videoPlayer.currentTime > 0 && !isNaN(this.videoPlayer.duration)) {
      this.progressListeners.map((listener) =>
        listener.setProgress(this.videoPlayer.currentTime)
      )

      if (0 == this.lastTime) {
        this.lastTime = this.videoPlayer.currentTime
      }

      this.elapsed += this.videoPlayer.currentTime - this.lastTime
      this.lastTime = this.videoPlayer.currentTime

      if (this.elapsed > 10) {
        this.reportPosition()
      }
    }
  }

  private reportPosition(): void {
    this.elapsed = 0
    this.reportListeners.map((cb) => cb(this.videoPlayer.currentTime, this.videoPlayer))
  }

  addProgressListener(progressListeners: CourseProgressInterface): void {
    this.progressListeners.push(progressListeners)
  }

  addReportListener(cb: CallableFunction): void {
    this.reportListeners.push(cb)
  }
}
