import { tpElement } from '../lib/component-decorator'
import { CourseProgressInterface } from '../lib/course-progress-interface'

@tpElement('course-list-progress')
export class CourseListProgress extends HTMLElement implements CourseProgressInterface {
  private percentage: HTMLSpanElement | null
  private progressBar: HTMLDivElement | null

  private duration: number = 0
  private progress: number

  constructor() {
    super()

    this.percentage = this.querySelector('.progress-kursinhalte span')
    this.progressBar = this.querySelector('.progress-bar')

    if (null !== this.progressBar) {
      this.duration = parseInt(this.progressBar.getAttribute('aria-valuemax')!)
      this.progress = Math.min(
        this.duration,
        parseInt(this.progressBar.getAttribute('aria-valuenow')!)
      )
    }
  }

  setProgress(progress: number) {
    if (null === this.percentage || null === this.progressBar) {
      return
    }
    this.progress = Math.max(this.progress, Math.min(progress, this.duration))

    let playedPercentage = Math.floor((this.progress / this.duration) * 100)

    this.percentage.innerHTML = `${playedPercentage}%`
    this.progressBar.style.width = `${playedPercentage}%`
  }
}
