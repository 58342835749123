import { tpElement } from '../lib/component-decorator'

@tpElement('growable-flex')
export class GrowableFlex extends HTMLElement {
  private flexContainer: HTMLDivElement
  private flexParent: HTMLDivElement
  private theGradient?: HTMLDivElement
  private marker?: HTMLDivElement
  private isMobile: boolean = true

  private baseHeight: string = '4rem'
  private maxBasis: number = 0
  private minBasis: number = 0
  private opened: boolean = false

  private theOrange: string = '#F79837'

  private down: string = `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 320 512"
      style="
        position: relative;
        top: -0.3em;
      "
    >
    <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
    <path
      fill="${this.theOrange}"
      d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"
    />
    </svg>
  `
  private up: string = `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 320 512"
      style="
        position: relative;
        top: 0.2em;
      "
    >
    <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
    <path
      fill="${this.theOrange}"
      d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"
    />
    </svg>
  `

  constructor() {
    super()

    this.flexContainer = this.parentElement! as HTMLDivElement
    this.flexParent = this.flexContainer.parentElement! as HTMLDivElement
    this.isMobile = undefined === document.body.dataset.aosDelay

    if (this.isMobile) {
      this.baseHeight = '10rem'
      this.setup()
    } else {
      this.baseHeight = '4rem'
      // Setup after AOS init (otherwise scrollHeight is too high)
      let observer = new MutationObserver(() => this.setup())
  
      observer.observe(this.flexContainer, {
        attributes: true,
        attributeFilter: ['class'],
      })
    }

  }

  private setup() {
    this.flexContainer.style.display = 'flex'
    this.flexContainer.style.flexDirection = 'column'

    this.style.overflowY = 'hidden'
    this.style.transition = 'flex-basis 0.5s ease-in-out'
    this.style.position = 'relative'
    this.style.flexBasis = '0'

    let baseHeight = this.flexParent.getBoundingClientRect().height
    let flexHeight = this.flexContainer.getBoundingClientRect().height
    this.maxBasis = this.scrollHeight
    this.minBasis = baseHeight - flexHeight

    console.log(this.maxBasis, this.minBasis)

    if (this.maxBasis > this.minBasis) {
      // Setup the Magic
      this.setupElements()
      this.setState()
    } else {
      // No magic needed, just display with scrollHeight
      this.setFlex(this.maxBasis)
    }
  }

  private setFlex(basis: number) {

    this.style.flexBasis = `max(${this.baseHeight}, ${basis}px)`
  }

  private setupElements() {
    if (this.theGradient) {
      return
    }
    this.theGradient = document.createElement('div')
    this.marker = document.createElement('div')

    this.theGradient.style.position = 'absolute'
    this.theGradient.style.zIndex = '101'
    this.theGradient.style.width = '100%'
    this.theGradient.style.height = '4rem'
    this.theGradient.style.bottom = '0'
    this.theGradient.style.left = '0'
    this.theGradient.style.background = `linear-gradient(
        178deg, 
        rgba(115,47,111,0) 0%, 
        rgba(115,47,111,1) 70%
      )`
    this.theGradient.style.transition = 'opacity 0.5s linear'

    this.marker.style.color = '#F79837'
    this.marker.style.position = 'absolute'
    this.marker.style.zIndex = '102'
    this.marker.style.bottom = '0'
    this.marker.style.right = '0'
    this.marker.style.cursor = 'pointer'
    this.marker.style.userSelect = 'none'

    this.appendChild(this.theGradient)
    this.appendChild(this.marker)

    this.marker.addEventListener('click', (e: Event) => this.toggle(e))
  }

  private setState() {
    if (this.marker) {
      this.marker.innerHTML = this.opened 
      ? `weniger ${this.up}`
      : `mehr lesen ${this.down}`
    }
    if (this.theGradient) {
      this.theGradient.style.opacity = this.opened ? '0' : '1'
    }

    this.setFlex(this.opened ? this.maxBasis : this.minBasis)
  }

  private toggle(e: Event) {
    e.stopPropagation()

    this.opened = !this.opened
    this.setState()
  }
}
