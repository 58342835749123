import { tpElement } from '../lib/component-decorator'

@tpElement('password-group')
export class PassworGroup extends HTMLElement {
  private span: HTMLSpanElement
  private input: HTMLInputElement

  constructor() {
    super()

    this.input = this.querySelector('input')!

    this.span = document.createElement('span')
    this.span.classList.add('password-showhide')
    this.span.innerHTML = `
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.8656 4.39435C17.7049 4.21513 13.8404 0 9.00001 0C4.15963 0 0.295119 4.21513 0.13442 4.39435C-0.0448066 4.59467 -0.0448066 4.89751 0.13442 5.09783C0.295119 5.27706 4.1597 9.49218 9.00001 9.49218C13.8403 9.49218 17.7049 5.27706 17.8656 5.09783C18.0448 4.89751 18.0448 4.59467 17.8656 4.39435ZM9.00001 8.4375C6.96478 8.4375 5.30861 6.78132 5.30861 4.74609C5.30861 2.71086 6.96478 1.05469 9.00001 1.05469C11.0352 1.05469 12.6914 2.71086 12.6914 4.74609C12.6914 6.78132 11.0352 8.4375 9.00001 8.4375Z" fill="#EFE1ED"/>
            <path d="M9.52734 3.69141C9.52734 3.1609 9.7907 2.6942 10.1913 2.40715C9.83183 2.22311 9.43084 2.10938 9 2.10938C7.54618 2.10938 6.36328 3.29228 6.36328 4.74609C6.36328 6.19991 7.54618 7.38281 9 7.38281C10.3016 7.38281 11.379 6.4324 11.5918 5.19054C10.5299 5.53243 9.52734 4.72929 9.52734 3.69141Z" fill="#EFE1ED"/>
        </svg>
    `
    this.appendChild(this.span)

    this.span.addEventListener('click', () => this.toggle())
  }

  private toggle() {
    this.input.setAttribute(
      'type',
      'text' == this.input.getAttribute('type') ? 'password' : 'text'
    )
  }
}
